<template>
  <standard-page :title="process_definition_step.name" v-if="process_definition_step">
    <template v-slot:breadcrumb>
      <span>
        <router-link :to="{ name: 'provenances-home'}">Provenance</router-link>
      </span>
      <span>
        <router-link :to="{ name: 'ongoing-process'}">Ongoing Processes</router-link>
      </span>
      <span v-if="process">
        <router-link :to="{ name: 'ongoing-process-steps'}">Process: {{process.name | truncate(10, '.....')}}</router-link>
      </span>
      <span v-if="process_definition_step">Step: {{process_definition_step.name | truncate(10, '.....')}}</span>
    </template>
    <template v-slot:content>
      <div class="az-content-label mg-b-5">Add Attributes</div>
      <div class="mg-b-20" v-html="$DEFINITIONS.provenance.addAttribute"></div>
      <form @submit.prevent="handleSubmit">
          <div class="pd-30 pd-sm-40 bg-gray-200">
            <div class="tab-pane-body">
            <div class="row row-xs align-items-center mg-b-20" v-for="(row, index) in attributes">
                <div class="col-md-1 d-none d-xl-block">
                    <label class="form-label mg-b-0">{{index+1}}.</label>
                </div>
                <div class="col-md-3 mg-t-5 mg-md-t-0">
                    <input :class="{'is-invalid': submitted && errors.has('name'+index)}" :name="'name'+index" class="form-control rounded" data-vv-as="Attribute Name" placeholder="Enter name" style="height: 42px" type="text" v-model="row.name" v-validate="'required'">
                    <div class="invalid-feedback" v-if="submitted && errors.has('name'+index)">{{ errors.first('name'+index) }}</div>
                </div>
                <div class="col-md-3 mg-t-5 mg-md-t-0">
                    <select :class="{'is-invalid': submitted && errors.has('type'+index)}" :name="'type'+index" aria-hidden="true" class="form-control select2-no-search select2-hidden-accessible rounded" data-select2-id="13" data-vv-as="Attribute Type" style="height: 42px" tabindex="-1" v-model="row.type" v-validate="'required'">
                      <option v-bind:key="index" v-for="(type, index) in data_types">{{type}}</option>
                    </select>
                    <div class="invalid-feedback" v-if="submitted && errors.has('type'+index)">{{ errors.first('type'+index) }}</div>
                </div>
                <div class="col-md-3 mg-t-5 mg-md-t-0" v-if="row.type === 'Text'">
                    <input :class="{'is-invalid': submitted && errors.has('value'+index)}" :name="'value'+index" class="form-control rounded" data-vv-as="Attribute Value" placeholder="Enter value" style="height: 42px" type="text" v-if="row.type === 'Text'" v-model="row.value" v-validate="'required'">
                    <div class="invalid-feedback" v-if="submitted && errors.has('value'+index)">{{ errors.first('value'+index) }}</div>
                </div>
                <div class="col-md-3 mg-t-5 mg-md-t-0" v-if="row.type === 'Number'">
                    <input :class="{'is-invalid': submitted && errors.has('value'+index)}" :name="'value'+index" class="form-control rounded" data-vv-as="Attribute Value" placeholder="Enter value" style="height: 42px" type="number" v-if="row.type === 'Number'" v-model="row.value" v-validate="'required'">
                    <div class="invalid-feedback" v-if="submitted && errors.has('value'+index)">{{ errors.first('value'+index) }}</div>
                </div>
                <div class="col-md-3 mg-t-5 mg-md-t-0" v-if="row.type === 'Date'">
                    <input :class="{'is-invalid': submitted && errors.has('value'+index)}" :name="'value'+index" class="form-control rounded" data-vv-as="Attribute Value" type="date" v-if="row.type === 'Date'" v-model="row.value" v-validate="'required'">
                    <div class="invalid-feedback" v-if="submitted && errors.has('value'+index)">{{ errors.first('value'+index) }}</div>
                </div>
                <div class="custom-file col-md-3 mg-t-5 mg-md-t-0" v-if="row.data_type === 'File'">
                    <input :class="{'is-invalid': submitted && errors.has('value'+index)}" :name="'value'+index" :id="'value'+index" @change="fileChange" class="custom-file-input" type="file">
                    <label :for="index" class="custom-file-label">{{row.file_name}}</label>
                </div>
                <div class="col-md-3 mg-t-5 mg-md-t-0" v-if="row.type === 'Yes/No'">
                    <div class="custom-control custom-radio custom-control-inline">
                      <input :class="{'is-invalid': submitted && errors.has('_yesno'+index)}" :id="index+'_yes'" :name="'_yesno'+index" class="custom-control-input" data-vv-as="Attribute Value" type="radio" v-bind:value=true v-model="row.value" v-validate="'required'">
                      <label :for="index+'_yes'" class="custom-control-label">Yes</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input :class="{'is-invalid': submitted && errors.has('_yesno'+index)}" :id="index+'_no'" :name="'_yesno'+index" class="custom-control-input" data-vv-as="Attribute Value" type="radio" v-bind:value=false v-model="row.value" v-validate="'required'">
                      <label :for="index+'_no'" class="custom-control-label">No</label>
                    </div>
                    <div class="invalid-feedback" v-if="submitted && errors.has('_yesno'+index)">{{ errors.first('_yesno'+index) }}</div>
                </div>
                <div class="col-md mg-t-10 mg-md-t-0" v-if="attributes.length > 1">
                    <a href="javascript:void(0)" @click="removeRow(index)" class="btn btn-block btn-danger rounded"><i class="fa fa-trash-alt"></i></a>
                </div>
                <div class="col-md mg-t-10 mg-md-t-0">
                    <a href="javascript:void(0)" v-if="index === attributes.length-1" @click="addRow" class="btn btn-block btn-success rounded" type="button"><i class="fa fa-plus-square"></i></a>
                </div>
            </div>
        </div>
          </div>
          <div class="row row-sm mg-t-20">
              <div class="col-lg-2 offset-lg-8 mg-t-5">
                  <button class="btn btn-primary w-100 rounded font-weight-bold" type="submit"> Submit
                  </button>
              </div>
              <div class="col-lg-2  mg-t-5">
                  <button @click="reset" class="btn btn-secondary w-100 rounded font-weight-bold" type="reset"> Reset
                  </button>
              </div>
          </div>
      </form>
    </template>
  </standard-page>
</template>

<script>
import EventBus from "@/event-bus";

export default {
  name: "AddAttributes",
  props: ['registryid', 'processdefinitionid', 'processid', 'processstepid', 'processdefinitionstepid', 'status'],
  data() {
    return {
      submitted: false,
      process: null,
      process_definition_step: null,
      attributes: [
        {
          "name": null,
          "type": "Text",
          "value": null
        }
      ],
      data_types: ['Text', 'Number', 'Yes/No', 'Date'],
        did_attachments: []
    };
  },
  mounted() {
    this.init();
    // AmazeUI Datetimepicker
    $('#datetimepicker').datetimepicker({
      format: 'yyyy-mm-dd hh:ii',
      autoclose: true
    });
  },
  methods: {
    async init() {
      await this.getProcess();
      await this.getProcessStep();
    },
    async getProcess() {
      try {
        let { data } = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes/${this.processid}`);
        this.process = data;
      } catch (e) {

      } finally {
      }
    },
    async getProcessStep() {
      try {
        let { data } = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/definition_steps/${this.processdefinitionstepid}`);
        this.process_definition_step = data;
      } catch (e) {
        this.$toastr.e("Error retrieving Process Definition", "Error");
      } finally {

      }
    },
    addRow() {
      this.attributes.push({
        "name": null,
        "type": "Text",
        "value": null
      });
    },
    removeRow(index) {
      this.attributes.splice(index, 1);
    },
    getDatatype(type, val) {
      switch (type) {
        case "Text":
          return 'String';
        case "Number":
          if (val >= 0 && val < 256) {
            return "U8";
          } else if (val > 255 && val < 65537) {
            return "U16";
          } else if (val > 65536 && val < 4000001) {
            return "U32";
          } else if (val) {
            return "U128";
          }
        case "Yes/No":
          return "Bool";
        case "Date":
          return "Date";
      }
    },
    addAttribute() {
      if (this.processstepid) {
        this.addToStep();
      } else {
        this.addProcessStep();
      }
    },
  async fileChange(e) {
      this.did_attachments = this.did_attachments.filter(a => a.name !== e.target.name)
      let file = e.target.files[0];
      this.attributes[e.target.id].file_name = file.name;
      this.did_attachments.push({
          name: e.target.name,
          file: file
      });
  },
    handleSubmit(e) {
      this.submitted = true;
      this.$validator.validate()
        .then(valid => {
          if (valid) {
            if (this.processstepid) {
              this.addToStep();
            } else {
              this.addProcessStep();
            }
          }
        });
    },
      async attachDidDocs(processstepid) {
          for (let i = 0; i < this.did_attachments.length; i++) {
              let attachment = this.did_attachments[i];
              let f = new FormData();
              f.append('file', attachment.file);
              await this.$provenance.post(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes/${this.processid}/process_steps/${processstepid}/documents?property_name=${attachment.name}`, f, {
                  headers: {
                      'Content-Type': 'multipart/form-data',
                      'Authorization': 'BWS ' + localStorage.getItem("token")
                  }
              })
          }
      },
    async addProcessStep() {
      let mapped_attributes = this.attributes.map(s => ({
        ...s,
        value: s.value.toString(),
        type: this.getDatatype(s.type, s.value)
      }));
      try {
        EventBus.$emit('openLoader');
        await this.$provenance.post(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes/${this.processid}/process_steps`, {
          process_definition_step: this.processdefinitionstepid ? Number(this.processdefinitionstepid) : null,
          attributes: mapped_attributes
        });
        this.reset();
        await this.$router.push({ name: 'view-future-ongoing-process-step' });
      } catch (e) {
        this.$toastr.e("Process Step creation failed", 'Error');
      } finally {
        EventBus.$emit('closeLoader');
      }
    },
    async addToStep() {
      let _mapped_attributes = this.attributes.map(s => ({
        ...s,
        value: s.value.toString(),
        type: this.getDatatype(s.type, s.value)
      }));
      try {
        EventBus.$emit('openLoader');
        await this.$provenance.patch(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes/${this.processid}/process_steps/${this.processstepid}`, {
          add_attributes: _mapped_attributes
        });
        this.reset();
        await this.$router.push({ name: 'view-ongoing-process-step' });
      } catch (e) {
        this.$toastr.e("Attribute creation failed", 'Error');
      } finally {
        EventBus.$emit('closeLoader');
      }
    },
    reset() {
      this.submitted = false;
      this.attributes = [
        {
          "name": null,
          "type": "Text",
          "value": null
        }
      ];
    }
  }
}
</script>

<style scoped>
</style>